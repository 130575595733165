import en from './en.json'
import es from './es.json'
import { translations } from 'sima-suite-components'

const defaultLocales = {
  en: { ...translations.en, ...en },
  es: { ...translations.es, ...es }
}

export default defaultLocales
