
import { onBeforeMount, ref } from 'vue'
import { getCurrentInstanceContext, useRouter, SButtonSize } from 'sima-suite-components'
import { useConfigurationsStore, useAppointmentWizardStore } from '@/stores'

export default {
  name: 'AppointmentConfirm',
  setup () {
    const { router } = useRouter(getCurrentInstanceContext())

    const buttonSize = SButtonSize.big

    const brandLogo = ref(useConfigurationsStore().brandLogo ? `${require(`@/assets/themes/logos/${useConfigurationsStore().brandLogo}`)}` : null)

    onBeforeMount(() => {
      useAppointmentWizardStore().$reset()
    })

    const goToStart = () => {
      router.push({ name: 'AppointmentWizard' })
    }

    return {
      brandLogo,
      buttonSize,
      goToStart
    }
  }
}
