
import { useConfigurationsStore } from '@/stores'
import { computed } from 'vue'

export default {
  name: 'NewRGPDPolicy',
  setup () {
    const privacyPolicyUrl = computed(() => useConfigurationsStore().privacyPolicyUrl)
    const webOwnerName = computed(() => useConfigurationsStore().webOwnerName)

    return {
      privacyPolicyUrl,
      webOwnerName
    }
  }
}
