import { defineStore } from 'pinia'
import { createNoError, createRelevantError, useMessages, ErrorResponse } from 'sima-suite-components'
import { getShort, useTranslator } from 'sima-suite-translator'
import {
  WorkshopsClient,
  VehiclesClient,
  BrandsClient,
  MarketServicesClient,
  IMarketServiceDto,
  WorkshopAvailabilityClient,
  IWorkshopAvailabilityDto,
  IAvailableHourForAdviserDto,
  AppointmentsClient,
  AppointmentOnlineCreationRequest,
  CustomerRequest,
  ServiceRequest,
  VehicleRequest,
  IMarketBrandDto,
  WorkshopCampaignDto
} from 'online-customer-services-ts'
import { WorkshopService } from '@/helpers/types'
import { createEmptyContact, createEmptyVehicle, createEmptyWorkshop } from '@/helpers/constructors'
import { State } from './types'
import { createWorkshopDate, getTeamId } from '@/utils'
import dayjs from '@/utils/dayjs'
import * as Sentry from '@sentry/vue'

const MAX_AVAILABILITY_CALLS = 2

let brandClient: BrandsClient
let workshopClient: WorkshopsClient
let servicesClient: MarketServicesClient
let availabilityClient: WorkshopAvailabilityClient
let appointmentsClient: AppointmentsClient
let vehiclesClient: VehiclesClient

export function initBrandsClient (client: BrandsClient) {
  brandClient = client
}

export function initWorkshopsClient (client: WorkshopsClient) {
  workshopClient = client
}

export function initServicesClient (client: MarketServicesClient) {
  servicesClient = client
}

export function initAvailabilityClient (client: WorkshopAvailabilityClient) {
  availabilityClient = client
}

export function initAppointmentsClient (client: AppointmentsClient) {
  appointmentsClient = client
}

export function initVehiclesClient (client: VehiclesClient) {
  vehiclesClient = client
}

export const useStore = defineStore({
  id: 'AppointmentWizard',
  state: (): State => ({
    appointmentConfirmed: undefined,
    availabilityCalls: 0,
    brands: [],
    contact: createEmptyContact(),
    contactFormValid: false,
    error: createNoError(),
    isLoading: false,
    vehicle: createEmptyVehicle(),
    vehicleFormValid: false,
    workshopAvailability: [],
    workshopAvailabilityAdviser: undefined,
    workshopAvailabilityDate: '',
    workshopAvailabilityRange: NaN,
    workshops: [],
    workshopSelected: createEmptyWorkshop(),
    workshopServices: [],
    campaigns: []
  }),
  actions: {
    async getWorkshops (hostId: string) {
      try {
        this.isLoading = true
        this.workshops = await workshopClient.getMarketWorkshops(hostId)

        Sentry.configureScope((scope) => {
          scope.addAttachment({ filename: `get-workshops${hostId}.txt`, data: JSON.stringify(this.workshops) })
        })
      } catch (err: unknown) {
        console.error(err)
        this.error = createRelevantError(err as ErrorResponse, useMessages())
      } finally {
        this.isLoading = false
      }
    },
    async getBrands (hostId: string) {
      try {
        this.isLoading = true
        this.brands = await brandClient.getMarketBrands(hostId)

        Sentry.configureScope((scope) => {
          scope.addAttachment({ filename: `get-market-${hostId}.txt`, data: JSON.stringify(this.brands) })
        })

        if (this.workshopSelected.brands?.length === 1) {
          this.vehicle.brand = this.brands.find(brand => brand.code === this.workshopSelected.brands![0].code)
        } else if (this.brands.length === 1) {
          this.vehicle.brand = this.brands[0]
        }
      } catch (err: unknown) {
        console.error(err)
        this.error = createRelevantError(err as ErrorResponse, useMessages())
      } finally {
        this.isLoading = false
      }
    },
    async getVehicle () {
      this.vehicle.vin = ''
      try {
        const response = await vehiclesClient.getVehicleByPlate3(this.vehicle.plate)
        const { vin } = response
        this.vehicle.vin = vin

        Sentry.configureScope((scope) => {
          scope.addAttachment({ filename: 'get-vehicle.txt', data: JSON.stringify(response) })
        })
      } catch (err: any) {
        console.error(err)
        if (err.status === 500) {
          this.error = createRelevantError(err as ErrorResponse, useMessages())
        }
      }
    },
    resetAvailability () {
      this.workshopAvailability = []
      this.workshopAvailabilityAdviser = undefined
      this.availabilityCalls = 0
      this.workshopAvailabilityDate = ''
      this.workshopAvailabilityRange = NaN
    },
    async getServices (hostId: string) {
      try {
        this.isLoading = true
        const response = await servicesClient.getMarketServices(hostId, getShort())
        this.workshopServices = response.map((service: IMarketServiceDto) => ({ ...service, checked: false, remark: '' }))
      } catch (err: unknown) {
        console.error(err)
        this.error = createRelevantError(err as ErrorResponse, useMessages())
      } finally {
        this.isLoading = false
      }
    },
    async getWorkshopAvailability (hostId: string, date: Date) {
      this.isLoading = true
      const abbreviation = this.vehicle.brand && this.vehicle.brand.code ? this.vehicle.brand.code : ''
      const fromDate = createWorkshopDate(dayjs(date.toISOString()).format('YYYY-MM-DD'), this.workshopSelected.startingWorkHour!)
      const toDate = createWorkshopDate(dayjs(date.toISOString()).format('YYYY-MM-DD'), this.workshopSelected.startingWorkHour!)
      const selectedDate = dayjs(date.toISOString()).isToday() ? dayjs(date.toISOString()).add(this.workshopSelected.daysOfMarginToRequest, 'day').toDate() : date
      try {
        const { workshopAvailability, campaigns } = await availabilityClient.getWorkshopAvailability24(hostId, selectedDate, fromDate, toDate, this.workshopSelected.id, this.servicesSelectedIds, abbreviation, this.vehicle.vin)
        this.workshopAvailability = workshopAvailability!
        this.campaigns = campaigns || []
        this.availabilityCalls = this.availabilityCalls + 1
        if (this.availableDays.length === 0 && this.availabilityCalls < MAX_AVAILABILITY_CALLS) {
          date.setDate(1)
          date.setMonth(date.getMonth() + 1)
          this.workshopAvailabilityDate = date.toISOString().slice(0, 10)
          await this.getWorkshopAvailability(hostId, date)
        } else {
          this.isLoading = false
        }
      } catch (err: unknown) {
        console.error(err)
        this.error = createRelevantError(err as ErrorResponse, useMessages())
        this.isLoading = false
      }
    },
    async createAppointment (hostId: string) {
      this.isLoading = true
      const appointmentRequestData = new AppointmentOnlineCreationRequest({
        adviserId: this.workshopAvailabilityAdviser!.adviserId,
        cultureName: getShort(),
        customer: new CustomerRequest({
          customerId: undefined,
          email: this.contact.email,
          mobile: this.contact.mobile,
          name: this.contact.completeName,
          phone: this.contact.phone
        }),
        services: this.servicesSelected.map((service: WorkshopService) => (new ServiceRequest({
          id: service.id,
          remarks: service.remark
        }))),
        teamId: getTeamId(this.workshopAvailability, this.workshopAvailabilityDate),
        vehicle: new VehicleRequest({
          brandCode: this.vehicle.brand!.code,
          kms: this.vehicle.kilometers,
          model: this.vehicle.model,
          plate: this.vehicle.plate,
          vin: this.vehicle.vin === '' ? undefined : this.vehicle.vin
        }),
        when: createWorkshopDate(this.workshopAvailabilityAdviser!.adviserDate!, this.workshopAvailabilityAdviser!.adviserTime!),
        workshopId: this.workshopSelected.id,
        campaigns: this.campaigns.map(campaign => new WorkshopCampaignDto(campaign))
      })
      try {
        await appointmentsClient.createAppointment(hostId, appointmentRequestData)
        this.appointmentConfirmed = true
      } catch (err: unknown) {
        console.error(err)
        this.appointmentConfirmed = false
        this.error = createRelevantError(err as ErrorResponse, useMessages(), useTranslator())
      } finally {
        this.isLoading = false
      }
    }
  },
  getters: {
    availableDays: (state): Date[] => {
      const availableDays = state.workshopAvailability.filter(day => day.advisers!.length > 0)
      return availableDays.map(day => {
        return new Date(dayjs(day.advisers![0].dateTime).format('YYYY-MM-DD'))
      })
    },
    availableRangeHours: (state): number[] => {
      const availableDay: IWorkshopAvailabilityDto | undefined = state.workshopAvailability.find((day: IWorkshopAvailabilityDto) => {
        if (day.advisers?.length === 0) return false

        return day.advisers![0].adviserDate === state.workshopAvailabilityDate
      })
      if (availableDay) {
        const availableAdviserHours: number[] = availableDay.advisers!.map((adviser: IAvailableHourForAdviserDto) => {
          return Number(adviser.adviserTime!.split(':')[0])
        })
        return [...new Set(availableAdviserHours)]
      }

      return []
    },
    availableAdvisers: (state): IAvailableHourForAdviserDto[] => {
      const currentDateAvailability = state.workshopAvailability.find(day => {
        if (day.advisers?.length === 0) return false

        return day.advisers![0].adviserDate === state.workshopAvailabilityDate
      })
      if (currentDateAvailability) {
        return currentDateAvailability.advisers!.filter(adviser => Number(adviser.adviserTime!.split(':')[0]) === state.workshopAvailabilityRange)
      }

      return []
    },
    servicesSelected: (state): WorkshopService[] => {
      return state.workshopServices.filter((service) => service.checked)
    },
    servicesSelectedIds: (state): number[] => {
      const servicesSelected = state.workshopServices.filter(services => services.checked === true)
      return servicesSelected.map(service => service.id)
    },
    workshopBrands: (state): IMarketBrandDto[] => {
      const workshopBrands = state.workshopSelected.brands!.map(brand => brand.code)
      return state.brands.filter(brand => workshopBrands.includes(brand.code))
    }
  }
})
