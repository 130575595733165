
import { Ref, computed, onMounted, ref, watch } from 'vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { useConfigurationsStore, useAppointmentWizardStore } from '@/stores'
import { createEmptyContact } from '../../helpers/constructors'
import NewRGPDPolicy from '@/containers/NewRGPDPolicy/NewRGPDPolicy.vue'

export default {
  name: 'ContactForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    NewRGPDPolicy
  },
  setup () {
    const useNewRGPDPolicy = computed(() => useConfigurationsStore().useNewRGPDPolicy)
    const conditionsUrl = computed(() => useConfigurationsStore().conditionsUrl)
    const validator: Ref<InstanceType<typeof ValidationObserver> | null> = ref(null)

    const contact = ref(createEmptyContact())
    const checkPolicies = ref(useNewRGPDPolicy.value)
    const checkPoliciesError = ref(false)

    onMounted(() => {
      if (useNewRGPDPolicy.value) {
        useConfigurationsStore().getConditionsUrl(
          useConfigurationsStore().hostId,
          useAppointmentWizardStore().vehicle.brand && useAppointmentWizardStore().vehicle.brand!.code ? useAppointmentWizardStore().vehicle.brand!.code! : null,
          useAppointmentWizardStore().workshopSelected.simaId
        )
      }
    })

    contact.value = useAppointmentWizardStore().contact

    const onCompleteNameInput = (completeName: string) => {
      contact.value.completeName = completeName.trim()
    }

    const onEmailInput = (email: string) => {
      contact.value.email = email.trim()
    }

    const onMobileInput = (mobile: string) => {
      contact.value.mobile = mobile.trim()
    }

    const onPhoneInput = (phone: string) => {
      contact.value.phone = phone.trim()
    }

    const onCheckPolicies = (check: boolean) => {
      checkPolicies.value = check
    }

    watch(
      () => [contact.value, checkPolicies.value],
      async () => {
        useAppointmentWizardStore().contact = contact.value

        if (validator.value) {
          validator.value.validate().then((valid: boolean) => {
            useAppointmentWizardStore().contactFormValid = valid && checkPolicies.value
          })
        }
      }, {
        deep: true
      }
    )

    return {
      checkPolicies,
      checkPoliciesError,
      conditionsUrl,
      contact,
      onCheckPolicies,
      onCompleteNameInput,
      onEmailInput,
      onMobileInput,
      onPhoneInput,
      useNewRGPDPolicy,
      validator
    }
  }
}
