var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-row gap-m pt-s h-full"},[_c('div',{staticClass:"hidden md:flex md:basis-7/12 justify-center border border-[#E0E0E0] rounded-2xl px-l py-16"},[_c('img',{staticClass:"w-full",attrs:{"src":require('@/assets/step-6.svg'),"alt":_vm.$translate('AppointmentWizard.Steps.Step6.AltImage')}})]),_c('div',{staticClass:"w-full md:basis-5/12 h-full overflow-y-auto"},[_c('h2',{staticClass:"font-bold text-sm text-neutral px-s pb-s"},[_vm._v(" "+_vm._s(_vm.$translate("AppointmentWizard.Steps.Step6.ContactInfo"))+" ")]),_c('form',[_c('ValidationObserver',{ref:"validator",staticClass:"w-full flex flex-wrap flex-col"},[_c('ValidationProvider',{attrs:{"name":"CompleteName","rules":{
            required: true,
            regex: /^[0-9a-zA-ZÀ-ÿ _.·-]+$/,
            max: 100
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('s-text-field',{attrs:{"error-messages":_vm.validator && _vm.validator.fields.CompleteName && !_vm.validator.fields.CompleteName.pristine ? errors : null,"label":`${_vm.$translate('AppointmentWizard.Steps.Step6.CompleteName')}*`,"placeholder":_vm.$translate('AppointmentWizard.Steps.Step6.CompleteName'),"value":_vm.contact.completeName},on:{"input":_vm.onCompleteNameInput}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email|max:50"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('s-text-field',{attrs:{"error-messages":_vm.validator && _vm.validator.fields.Email && !_vm.validator.fields.Email.pristine ? errors : null,"label":`${_vm.$translate('AppointmentWizard.Steps.Step6.Email')}*`,"placeholder":_vm.$translate('AppointmentWizard.Steps.Step6.Email'),"value":_vm.contact.email},on:{"input":_vm.onEmailInput}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Mobile","rules":{
            required: _vm.contact.phone === '',
            regex: /^[0-9]+$/,
            max: 50
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('s-text-field',{attrs:{"error-messages":_vm.validator && _vm.validator.fields.Mobile && !_vm.validator.fields.Mobile.pristine ? errors : null,"label":`${_vm.$translate('AppointmentWizard.Steps.Step6.Mobile')}**`,"placeholder":_vm.$translate('AppointmentWizard.Steps.Step6.Mobile'),"value":_vm.contact.mobile},on:{"input":_vm.onMobileInput}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Phone","rules":{
            required: _vm.contact.mobile === '',
            regex: /^[0-9]+$/,
            max: 15
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('s-text-field',{attrs:{"error-messages":_vm.validator && _vm.validator.fields.Phone && !_vm.validator.fields.Phone.pristine ? errors : null,"label":`${_vm.$translate('AppointmentWizard.Steps.Step6.Phone')}**`,"placeholder":_vm.$translate('AppointmentWizard.Steps.Step6.Phone'),"value":_vm.contact.phone},on:{"input":_vm.onPhoneInput}})]}}])}),(_vm.useNewRGPDPolicy)?_c('NewRGPDPolicy'):_c('div',{staticClass:"flex justify-start items-center ml-[0.375rem]"},[_c('s-checkbox',{staticClass:"ml-xs",attrs:{"checked":_vm.checkPolicies},on:{"onClick":_vm.onCheckPolicies}}),_c('a',{staticClass:"font-normal text-sm hover:underline",attrs:{"target":"_blank","href":_vm.conditionsUrl}},[_vm._v(" "+_vm._s(_vm.$translate('AppointmentWizard.Steps.Step6.Policies'))+" ")])],1)],1),_c('p',{staticClass:"font-normal text-xs text-neutral5 mx-s"},[_vm._v(" "+_vm._s(_vm.$translate("AppointmentWizard.Steps.Step6.RequiredNotes"))+" ")]),_c('p',{staticClass:"font-normal text-xs text-neutral5 mx-s"},[_vm._v(" "+_vm._s(_vm.$translate("AppointmentWizard.Steps.Step6.Notes"))+" ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }