import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { AppointmentWizard, AppointmentConfirm, AppointmentFailure, CookiesPolicy } from '@/views'

import { useAppointmentWizardStore } from '@/stores'

Vue.use(VueRouter)
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hideImage: any;
  }
}

export function buildRoutes (): Array<RouteConfig> {
  return [
    {
      path: '/',
      name: 'AppointmentWizard',
      component: AppointmentWizard,
      beforeEnter: (to, from, next) => {
        window.hideImage()
        next()
      }
    },
    {
      path: '/confirm',
      name: 'AppointmentConfirm',
      component: AppointmentConfirm,
      beforeEnter: (to, from, next) => {
        if (useAppointmentWizardStore().appointmentConfirmed) {
          next()
        } else {
          next({ name: 'AppointmentWizard' })
        }
      }
    },
    {
      path: '/failure',
      name: 'AppointmentFailure',
      component: AppointmentFailure,
      beforeEnter: (to, from, next) => {
        if (useAppointmentWizardStore().appointmentConfirmed === false) {
          next()
        } else {
          next({ name: 'AppointmentWizard' })
        }
      }
    },
    {
      path: '/cookies-policy',
      name: 'CookiesPolicy',
      component: CookiesPolicy
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
}

export function initializeRouter () {
  Vue.use(VueRouter)

  return new VueRouter({
    routes: buildRoutes()
  })
}
