import { Icon } from 'leaflet'

// Do a Marker Image Fix https://vue2-leaflet.netlify.app/quickstart/#marker-icons-are-missing
export function fixMarkerLeafLet () {
  type D = Icon.Default & {
      _getIconUrl?: string;
    };
  delete (Icon.Default.prototype as D)._getIconUrl
  Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
  })
}
