
import { computed, Ref, ref } from 'vue'
import { getCurrentInstanceContext, useRouter, Breakpoints } from 'sima-suite-components'
import { useConfigurationsStore } from '@/stores'

export default {
  name: 'FooterLinks',
  setup () {
    const { router } = useRouter(getCurrentInstanceContext())

    const isOpenModal:Ref<boolean|undefined> = ref(undefined)

    const breakpoints = ref(Breakpoints(getCurrentInstanceContext()))

    const toggleModal = () => {
      isOpenModal.value = !isOpenModal.value
    }

    const navigateToCookiesPolicy = () => {
      const cookiesPolicyRoute = router.resolve({ name: 'CookiesPolicy' })
      window.open(cookiesPolicyRoute.href, '_blank')
    }

    const legalAdviser = computed(() => useConfigurationsStore().legalAdviserUrl)
    const privacyPolicy = computed(() => useConfigurationsStore().privacyPolicyUrl)
    const isDidomiId = computed(() => useConfigurationsStore().didomiId !== '')

    return {
      breakpoints,
      isDidomiId,
      isOpenModal,
      legalAdviser,
      navigateToCookiesPolicy,
      privacyPolicy,
      toggleModal
    }
  }
}
