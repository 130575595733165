
import WorkshopMap from '@/containers/WorkshopMap/WorkshopMap.vue'
import { Contact, WorkshopService } from '@/helpers/types'
import { computed } from 'vue'
import { useAppointmentWizardStore } from '@/stores'
import { getShort } from 'sima-suite-translator'

export default {
  name: 'SummaryAppointment',
  components: {
    WorkshopMap
  },
  setup () {
    const workshop = computed(() => useAppointmentWizardStore().workshopSelected)
    const vehicle = computed(() => useAppointmentWizardStore().vehicle)
    const services = computed(() => useAppointmentWizardStore().servicesSelected)
    const date = computed(() => useAppointmentWizardStore().workshopAvailabilityAdviser)
    const contact = computed(() => useAppointmentWizardStore().contact)

    const servicesToText = (services: WorkshopService[]): string => {
      let text = ''

      services.forEach((service: WorkshopService, index: number) => {
        text += service.name
        if (index < services.length - 1) {
          text += ', '
        }
      })

      return text
    }

    const locale = () => {
      return getShort() === 'es' ? 'es' : 'en'
    }

    const phonesToText = (contact: Contact): string => {
      let text = ''

      if (contact.mobile) {
        text += contact.mobile
      }

      if (contact.phone) {
        if (text.length > 0) {
          text += ' - '
        }

        text += contact.phone
      }

      return text
    }

    return {
      contact,
      date,
      phonesToText,
      services,
      servicesToText,
      vehicle,
      workshop,
      locale
    }
  }
}
