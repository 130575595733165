export default function GoogleTagManagerWrapper () {
  let workshop
  let service
  let maxReachedStep = 1
  let remarks = ''

  function withWorkshop (newWorkshop) {
    workshop = newWorkshop
  }

  function withServices (newService) {
    service = newService
  }

  function withRemarks (newRemarks) {
    remarks = newRemarks
  }

  function trackStep2 () {
    if (maxReachedStep >= 2) return
    dataLayerPush({
      virtualpageview: '/paso2/tumodelo',
      concesionario: workshop,
      event: 'pageview'
    })

    maxReachedStep = maxReachedStep + 1
  }

  function trackStep2Exit () {
    if (maxReachedStep >= 2) return
    dataLayerPush({
      virtualpageview: '/paso1/exit',
      concesionario: workshop,
      event: 'pageview'
    })
  }

  function trackStep3 () {
    if (maxReachedStep >= 3) return
    dataLayerPush({
      virtualpageview: '/paso3/servicios',
      concesionario: workshop,
      event: 'pageview'
    })
    maxReachedStep = maxReachedStep + 1
  }

  function trackStep4 () {
    if (maxReachedStep >= 4) return
    dataLayerPush({
      virtualpageview: '/paso4/fecha',
      concesionario: workshop,
      servicio: service,
      event: 'pageview',
      observaciones: remarks
    })
    maxReachedStep = maxReachedStep + 1
  }
  function trackStep5 () {
    pushStep('/paso5/disponibilidad', 5)
  }
  function trackNoAvailability () {
    dataLayerPush({
      event: 'gaEvent',
      eventCategory: 'interaccion',
      eventAction: 'nodisponibilidad',
      eventLabel: '/paso5/disponibilidad',
      eventValue: 1,
      nonInteractive: false
    })
  }
  function trackStep6 () {
    pushStep('/paso6/datosdecontaco', 6)
  }
  function trackStep7 () {
    pushStep('/paso7/confirmarcita', 7)
  }
  function trackStep8 () {
    pushStep('/paso8/citaconfirmada', 8)
  }

  function pushStep (step, stepNumber) {
    if (stepNumber <= maxReachedStep) return
    dataLayerPush({
      virtualpageview: step,
      concesionario: workshop,
      servicio: service,
      event: 'pageview'
    })
    maxReachedStep = maxReachedStep + 1
  }

  function dataLayerPush (stepObject) {
    /* eslint-disable no-undef */
    dataLayer.push(stepObject)
    /* eslint-enable */
  }

  return {
    withWorkshop,
    withServices,
    withRemarks,
    trackStep2,
    trackStep2Exit,
    trackStep3,
    trackStep4,
    trackStep5,
    trackStep6,
    trackStep7,
    trackStep8,
    trackNoAvailability
  }
}
