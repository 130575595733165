
import { computed, ref } from 'vue'
import { Breakpoints, getCurrentInstanceContext } from 'sima-suite-components'

const __default__ = {
  name: 'SWizard',
  props: {
    steps: {
      type: Array as () => string[],
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      default: '#1573e5'
    }
  },
  setup (props, context) {
    const breakpoints = ref(Breakpoints(getCurrentInstanceContext()))

    const offset = computed(() => props.currentStep === 0 || props.currentStep === props.steps.length - 1 ? 2 : 1)

    const showStep = (step: number): boolean => {
      if (breakpoints.value.isMobile) {
        const minShown = props.currentStep - offset.value >= 0 ? props.currentStep - offset.value : 0
        const maxShown = props.currentStep + offset.value < props.steps.length ? props.currentStep + offset.value : props.steps.length - 1

        return step >= minShown && step <= maxShown
      }

      return true
    }

    const onStep = (step: number) => {
      if (step <= props.currentStep) {
        context.emit('onStep', step)
      }
    }

    return {
      breakpoints,
      offset,
      onStep,
      showStep
    }
  }
}

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "40f5154a": (_vm.color)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__