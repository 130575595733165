
import { computed } from 'vue'
import { useConfigurationsStore } from '@/stores'

export default {
  name: 'CookiesPolicy',
  setup () {
    const webOwnerName = useConfigurationsStore().webOwnerName
    const privacyPolicy = computed(() => useConfigurationsStore().privacyPolicyUrl)

    const cookiesHeaders = [
      { text: 'Cookie', value: 'cookie' },
      { text: 'Descripción', value: 'description' },
      { text: 'Categoría', value: 'category' },
      { text: 'Persistencia', value: 'persistence' }
    ]

    const cookiesData = [
      {
        cookie: '_ga',
        description: 'usada para distinguir usuarios',
        category: 'Cookie funcional',
        persistence: '2 años'
      },
      {
        cookie: '_gid',
        description: 'usada para distinguir usuarios',
        category: 'Cookie funcional',
        persistence: '24 horas'
      },
      {
        cookie: '_gat_<property-id>',
        description: 'usada para controlar la ratio de peticiones',
        category: 'Cookie funcional',
        persistence: '1 minuto'
      }
    ]

    return {
      cookiesData,
      cookiesHeaders,
      privacyPolicy,
      webOwnerName
    }
  }
}
