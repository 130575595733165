
import { createEmptyWorkshop } from '@/helpers/constructors'
import { IMarketWorkshopDto } from 'online-customer-services-ts'

export default {
  name: 'WorkshopsList',
  props: {
    workshops: {
      type: Array as () => IMarketWorkshopDto[],
      required: true
    },
    currentWorkshop: {
      type: Object as () => IMarketWorkshopDto,
      default: () => createEmptyWorkshop()
    }
  },
  setup (_props, context) {
    const selectWorkshop = (workshop: IMarketWorkshopDto) => {
      context.emit('selectedWorkshop', workshop)
    }

    return {
      selectWorkshop
    }
  }
}
