

import { Ref, computed, ref, watch, reactive, onMounted, onActivated } from 'vue'
import { ValidationObserver, ValidationProvider } from 'sima-suite-validations'
import { STextFieldType, SelectItem, getCurrentInstanceContext, useRouter } from 'sima-suite-components'
import SLoading from '@/components/SLoading.vue'
import { IMarketBrandDto } from 'online-customer-services-ts'
import { createEmptyVehicle } from '@/helpers/constructors'
import { Vehicle } from '@/helpers/types'
import { useConfigurationsStore, useAppointmentWizardStore } from '@/stores'
import { getUrlParams } from '@/utils'

// TODO usar bien el store
// const appointmentWizardStore = useAppointmentWizardStore()
// const configurationsStore = useConfigurationsStore()

export default {
  name: 'VehicleForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    SLoading
  },
  setup () {
    const urlParams = getUrlParams()

    const currentInstanceContext = reactive(getCurrentInstanceContext())
    const { route } = useRouter(currentInstanceContext)

    const validator: Ref<InstanceType<typeof ValidationObserver> | null> = ref(null)

    const brands = computed(() => useAppointmentWizardStore().workshopBrands.map(BrandItems))
    const isLoading = computed(() => useAppointmentWizardStore().isLoading)
    const brandColor = ref(useConfigurationsStore().brandColor ? useConfigurationsStore().brandColor : undefined)

    onActivated(() => {
      useAppointmentWizardStore().getBrands(useConfigurationsStore().hostId)
      setSelectedBrand()
    })

    const vehicle: Ref<Vehicle> = ref(createEmptyVehicle())
    const selectedBrand: Ref<SelectItem | undefined> = ref(undefined)

    const setVehicleWithUrlParams = () => {
      if (urlParams.get('model')) {
        vehicle.value.model = urlParams.get('model')!
      }
      if (urlParams.get('plate')) {
        vehicle.value.plate = urlParams.get('plate')!
      }
      if (urlParams.get('VIN')) {
        vehicle.value.vin = urlParams.get('VIN')!
      }
      if (urlParams.get('vin')) {
        vehicle.value.vin = urlParams.get('vin')!
      }
    }

    onMounted(() => {
      setVehicleWithUrlParams()
    })

    const onBrandSelect = (brandCode: string) => {
      vehicle.value.brand = useAppointmentWizardStore().workshopBrands.find((brand) => brand.code === brandCode)!
    }

    const onModelInput = (model: string) => {
      vehicle.value.model = model.trim()
    }

    const onPlateInput = (plate: string) => {
      vehicle.value.plate = plate.trim()
    }

    const vehicleStored = computed(() => useAppointmentWizardStore().vehicle.brand?.name)

    const onKilometersInput = (kilometers: number) => {
      vehicle.value.kilometers = Math.trunc(kilometers)
    }

    const BrandItems = (brand: IMarketBrandDto): SelectItem => {
      return ({
        value: brand.code!,
        text: brand.name!
      })
    }

    const setSelectedBrand = () => {
      if (vehicle.value.brand) {
        selectedBrand.value = BrandItems(vehicle.value.brand)
      } else {
        selectedBrand.value = undefined
      }
    }

    vehicle.value = useAppointmentWizardStore().vehicle
    setSelectedBrand()

    watch(
      () => useAppointmentWizardStore().workshopBrands,
      () => {
        if (urlParams.get('brand')) {
          vehicle.value.brand = useAppointmentWizardStore().workshopBrands.find((brand: IMarketBrandDto) => brand.name?.toLowerCase() === (urlParams.get('brand')?.toLowerCase() as string))
          setSelectedBrand()
        }
      }
    )

    watch(
      () => vehicle.value,
      () => {
        setTimeout(() => {
          useAppointmentWizardStore().vehicle = vehicle.value
          useAppointmentWizardStore().resetAvailability()
          if (validator.value) {
            validator.value.validate().then((valid: boolean) => {
              // TODO: Revisar validador (va un paso atras)
              useAppointmentWizardStore().vehicleFormValid = vehicle.value.brand !== undefined && valid
            })
          }
        }, 0)
      }, {
        deep: true
      }
    )

    return {
      brands,
      onBrandSelect,
      onKilometersInput,
      onModelInput,
      onPlateInput,
      route,
      selectedBrand,
      STextFieldType,
      validator,
      vehicle,
      vehicleStored,
      isLoading,
      brandColor
    }
  }
}
