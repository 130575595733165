import { Settings } from '@/types'
import 'sima-suite-components/dist/sima-suite-components.css'
import 'leaflet/dist/leaflet.css'
import '@mdi/font/css/materialdesignicons.css'
import './style.css'
import Vue from 'vue'
// import VueGtag from 'vue-gtag'
import VueGtm from 'vue-gtm'
import { PiniaVuePlugin, createPinia } from 'pinia'
import { SimaSuiteConfigure } from 'sima-suite-components'
import { configure, useTranslator, getShort } from 'sima-suite-translator'
import { localize } from 'sima-suite-validations'
import { WorkshopsClient, BrandsClient, MarketServicesClient, WorkshopAvailabilityClient, AppointmentsClient, ConditionsUrlClient, CookiesUrlClient, VehiclesClient } from 'online-customer-services-ts'
import App from '@/App.vue'
import { initializeRouter } from '@/router'
import { loadConfiguration } from './configuration'
import defaultLocales from './translations'
import loadDidomi from './helpers/didomiHelper'
import { initConfigurationsStore, initWorkshopsClient, initBrandsClient, initServicesClient, initAvailabilityClient, initAppointmentsClient, initVehiclesClient } from '@/stores'

import { fixMarkerLeafLet } from '@/helpers/Leaflet'
import themes from './assets/themes'
import * as Sentry from '@sentry/vue'

import packageInfo from '../package.json'

const { version, name } = packageInfo

// Do a Marker Image Fix https://vue2-leaflet.netlify.app/quickstart/#marker-icons-are-missing
fixMarkerLeafLet()

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.config.productionTip = false

loadConfiguration().then(async result => {
  const { backendUrl, googleAnalyticsUA, host, didomiId } = result as Settings

  if (didomiId) {
    loadDidomi(document, didomiId)
  }

  const theme = themes[host] ? themes[host] : themes.default

  const simaSuite = SimaSuiteConfigure({
    themes: {
      light: {
        primary: theme.brandColor
      },
      dark: {}
    }
  })
  const router = initializeRouter()

  if (process.env.NODE_ENV === 'production') {
    Vue.use(VueGtm, {
      id: googleAnalyticsUA
    })
  }

  initConfigurationsStore(new ConditionsUrlClient(backendUrl), new CookiesUrlClient(backendUrl), { ...result as Settings, brandColor: theme.brandColor, brandFont: theme.brandFont, brandLogo: theme.brandLogo })
  initAppointmentsClient(new AppointmentsClient(backendUrl))
  initAvailabilityClient(new WorkshopAvailabilityClient(backendUrl))
  initBrandsClient(new BrandsClient(backendUrl))
  initServicesClient(new MarketServicesClient(backendUrl))
  initWorkshopsClient(new WorkshopsClient(backendUrl))
  initVehiclesClient(new VehiclesClient(backendUrl))

  Vue.use(configure(defaultLocales))

  localize(getShort())

  localize({
    [getShort()]: {
      names: {
        CompleteName: useTranslator().translate('AppointmentWizard.Steps.Step6.CompleteName'),
        Email: useTranslator().translate('AppointmentWizard.Steps.Step6.Email'),
        Kilometers: useTranslator().translate('AppointmentWizard.Steps.Step2.Kilometers'),
        Mobile: useTranslator().translate('AppointmentWizard.Steps.Step6.Mobile'),
        Model: useTranslator().translate('AppointmentWizard.Steps.Step2.Model'),
        Phone: useTranslator().translate('AppointmentWizard.Steps.Step6.Phone'),
        Plate: useTranslator().translate('AppointmentWizard.Steps.Step2.Plate')
      }
    }
  })

  /* initMonitoring({
    release: new Release(name, version),
    key: 'https://d2a7114c526d480d844b3559d53cd1be@o1427692.ingest.sentry.io/4505431958290432',
    router: router,
    url: backendUrl,
    environment: getEnvironment(),
    productionEnvironments: ['Prod', 'QA'],
    devMode: false
  }) */

  const isDevelopemnt = window.location.host.includes('localhost')

  if (!isDevelopemnt) {
    Sentry.init({
      Vue,
      dsn: 'https://d2a7114c526d480d844b3559d53cd1be@o1427692.ingest.sentry.io/4505431958290432',
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: ['localhost', backendUrl],
          routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        }),
        new Sentry.Replay()
      ],
      release: `${name}@${version}`,
      tracesSampleRate: 0.2,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      logErrors: false
    })
  }

  new Vue({
    ...simaSuite,
    router,
    pinia,
    render: h => h(App)
  }).$mount('#app')
})
