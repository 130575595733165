import { ConditionsUrlClient, CookiesUrlClient } from 'online-customer-services-ts'
import { createNoError, createRelevantError, ErrorResponse, logger, useMessages } from 'sima-suite-components'
import { defineStore } from 'pinia'
import { Settings } from '@/types'
import { State } from './types'
import * as Sentry from '@sentry/vue'

let conditionsClient: ConditionsUrlClient
let cookiesClient: CookiesUrlClient
let configurations: Settings

export function initConfigurations (conditionsUrlClient: ConditionsUrlClient, cookiesUrlClient: CookiesUrlClient, settings: Settings) {
  conditionsClient = conditionsUrlClient
  cookiesClient = cookiesUrlClient
  configurations = settings
}

export const useStore = defineStore({
  id: 'Configurations',
  state: (): State => ({
    brandColor: configurations.brandColor,
    brandFont: configurations.brandFont,
    brandLogo: configurations.brandLogo,
    conditionsUrl: '',
    cookiesUrl: '',
    didomiId: configurations.didomiId,
    error: createNoError(),
    hostId: configurations.host,
    isLoading: false,
    legalAdviserUrl: configurations.legalAdviserUrl,
    mapsKey: configurations.mapsKey,
    privacyPolicyUrl: configurations.privacyPolicyUrl,
    useFooter: configurations.useFooter,
    useLocalCookieBanner: configurations.useLocalCookieBanner,
    useNewRGPDPolicy: configurations.useNewRGPDPolicy,
    webOwnerName: configurations.webOwnerName
  }),
  actions: {
    async getConditionsUrl (hostId: string, brandCode: string | null, simaId: number) {
      try {
        this.isLoading = true
        this.conditionsUrl = await conditionsClient.getConditionsUrl(hostId, brandCode, simaId)
      } catch (err: unknown) {
        this.error = createRelevantError(err as ErrorResponse, useMessages())
        logger.logError(err as Error)
      } finally {
        this.isLoading = false
      }
    },
    async getCookiesUrl (hostId: string) {
      try {
        this.isLoading = true
        const data = await cookiesClient.getCookiesUrl(hostId)
        const { url } = data

        Sentry.configureScope((scope) => {
          scope.addAttachment({ filename: 'get-cookies-url.txt', data: JSON.stringify(data) })
        })

        this.cookiesUrl = url
      } catch (err: unknown) {
        console.error(err)
        this.error = createRelevantError(err as ErrorResponse, useMessages())
        logger.logError(err as Error)
      } finally {
        this.isLoading = false
      }
    }
  }
})
