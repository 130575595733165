
import { ref } from 'vue'

const __default__ = {
  name: 'SLoading',
  props: {
    color: {
      type: String,
      default: '#1573e5'
    }
  },
  setup (props) {
    const opacity = ref(`${props.color}50`)

    return {
      opacity
    }
  }
}

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "601e0c06": (_vm.color),
  "13798425": (_vm.opacity)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__