
import { computed, Ref, ref, watch, onMounted } from 'vue'
import { useTranslator } from 'sima-suite-translator'
import { getCurrentInstanceContext, useRouter, SButtonSize } from 'sima-suite-components'
import {
  AdviserAvailability,
  AppointmentDate,
  ContactForm,
  SummaryAppointment,
  VehicleForm,
  Workshops,
  WorkshopServices
} from '@/containers/'

import SWizard from '@/components/SWizard.vue'

import { useConfigurationsStore, useAppointmentWizardStore } from '@/stores'
import { changeLocationHash } from '@/utils'
import GoogleTagManagerWrapper from '@/utils/googleTagManagerWrapper.js'
import HotjarWrapper from '@/utils/hotjarWrapper.js'
import SLoading from '@/components/SLoading.vue'

export default {
  name: 'AppointmentWizard',
  components: {
    AdviserAvailability,
    AppointmentDate,
    ContactForm,
    SummaryAppointment,
    SWizard,
    VehicleForm,
    Workshops,
    WorkshopServices,
    SLoading
  },
  setup () {
    const googleTagManager = new GoogleTagManagerWrapper()
    const hotjar = new HotjarWrapper()
    const { router } = useRouter(getCurrentInstanceContext())

    onMounted(() => {
      changeLocationHash(steps[currentStep.value])
      // TODO Implement with google tag manager
      hotjar.stateChange(window.location.href)
    })

    const buttonSize = SButtonSize.big

    const isLoadingCreateAppointment = computed(() => useAppointmentWizardStore().isLoading)

    const brandLogo = ref(useConfigurationsStore().brandLogo ? `${require(`@/assets/themes/logos/${useConfigurationsStore().brandLogo}`)}` : null)
    const brandColor = ref(useConfigurationsStore().brandColor ? useConfigurationsStore().brandColor : undefined)

    const currentStep: Ref<number> = ref(0)
    const steps = [
      'Workshops',
      'VehicleForm',
      'WorkshopServices',
      'AppointmentDate',
      'AdviserAvailability',
      'ContactForm',
      'SummaryAppointment'
    ]

    const wizardTabs: Ref<string[]> = ref([
      useTranslator().translate('AppointmentWizard.Steps.Step1'),
      useTranslator().translate('AppointmentWizard.Steps.Step2'),
      useTranslator().translate('AppointmentWizard.Steps.Step3'),
      useTranslator().translate('AppointmentWizard.Steps.Step4'),
      useTranslator().translate('AppointmentWizard.Steps.Step5'),
      useTranslator().translate('AppointmentWizard.Steps.Step6'),
      useTranslator().translate('AppointmentWizard.Steps.Step7')
    ])

    const onChangeStep = (step: number) => {
      currentStep.value = step
      changeLocationHash(steps[currentStep.value])
    }

    const previousStep = () => {
      currentStep.value = currentStep.value - 1
      changeLocationHash(steps[currentStep.value])
      // TODO Implement with google tag manager
      hotjar.stateChange(window.location.href)
    }

    const registerPageViewEvent = () => {
      switch (currentStep.value) {
        case 0:
          googleTagManager.withWorkshop(useAppointmentWizardStore().workshopSelected.name)
          googleTagManager.trackStep2()
          break
        case 1:
          googleTagManager.trackStep3()
          break
        case 2:
          googleTagManager.withServices(useAppointmentWizardStore().servicesSelected
            .map((item) => item.name)
            .join(', '))
          googleTagManager.withRemarks(useAppointmentWizardStore().servicesSelected
            .filter((item) => item.hasRemarks)
            .map((item) => item.remark)
            .join(', '))
          googleTagManager.trackStep4()
          break
        case 3:
          googleTagManager.trackStep5()
          break
        case 4:
          googleTagManager.trackStep6()
          break
        case 5:
          googleTagManager.trackStep7()
          break

        default:
          break
      }
    }

    const nextStep = () => {
      if (useAppointmentWizardStore().workshopSelected.redirectUrl) {
        googleTagManager.trackStep2Exit()
        window.location.href = useAppointmentWizardStore().workshopSelected.redirectUrl!
      } else {
        if (currentStep.value === 1) {
          useAppointmentWizardStore().getVehicle()
        }
        if (process.env.NODE_ENV === 'production') {
          registerPageViewEvent()
        }
        currentStep.value = currentStep.value + 1
        changeLocationHash(steps[currentStep.value])
        // TODO Implement with google tag manager
        hotjar.stateChange(window.location.href)
      }
    }

    const confirmAppointment = () => {
      useAppointmentWizardStore().createAppointment(useConfigurationsStore().hostId)
    }

    const isNextDisabled = () => {
      switch (currentStep.value) {
        case 0:
          return isNaN(useAppointmentWizardStore().workshopSelected.id)
        case 1:
          return !useAppointmentWizardStore().vehicleFormValid
        case 2: {
          const services = useAppointmentWizardStore().workshopServices
          const someChecked = services.some((service) => service.checked)
          const withoutRemark = services.some((service) => service.checked && service.hasRemarks && (service.remark.length < 5 || service.remark.length > 70))
          return !someChecked || withoutRemark
        }
        case 3: {
          const hasDateSelected = useAppointmentWizardStore().workshopAvailabilityDate
          const hasHourSelected = Number.isNaN(useAppointmentWizardStore().workshopAvailabilityRange)
          return !(hasDateSelected && !hasHourSelected)
        }
        case 4:
          return !useAppointmentWizardStore().workshopAvailabilityAdviser
        case 5:
          return !useAppointmentWizardStore().contactFormValid
      }
    }

    watch(
      () => useAppointmentWizardStore().appointmentConfirmed,
      () => {
        if (useAppointmentWizardStore().appointmentConfirmed) {
          googleTagManager.trackStep8()
          router.push({ name: 'AppointmentConfirm' })
        } else {
          router.push({ name: 'AppointmentFailure' })
        }
      }
    )

    return {
      brandColor,
      brandLogo,
      buttonSize,
      confirmAppointment,
      currentStep,
      isLoadingCreateAppointment,
      isNextDisabled,
      nextStep,
      onChangeStep,
      previousStep,
      steps,
      wizardTabs
    }
  }
}
