export default function HotjarWrapper () {
  function stateChange (path) {
    if (window.hj) {
      window.hj('stateChange', path)
    }
  }

  return {
    stateChange
  }
}
