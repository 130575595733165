
import { computed, ref, watchEffect } from 'vue'
import { IMarketWorkshopDto } from 'online-customer-services-ts'
import { LMap, LMarker, LTileLayer } from 'vue2-leaflet'
import { createEmptyWorkshop } from '@/helpers/constructors'

export default {
  name: 'WorkshopMap',
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  props: {
    defaultZoom: {
      type: Number,
      default: 15
    },
    markers: {
      type: Array as () => IMarketWorkshopDto[],
      required: true
    },
    currentWorkshop: {
      type: Object as () => IMarketWorkshopDto,
      default: () => createEmptyWorkshop()
    }
  },
  setup (props, context) {
    const map = ref()
    const tileLayerUrl = ref('https://tile.openstreetmap.org/{z}/{x}/{y}.png')
    const attribution = ref('&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors')
    const zoom = ref(props.defaultZoom)

    const bounds = computed(() => [props.markers.map(workshopToArray)])
    const workshopToArray = (marker: IMarketWorkshopDto) => ([marker.latitude, marker.longitude])

    const zoomOnMarker = (marker: IMarketWorkshopDto) => {
      if (map.value) {
        map.value.mapObject.setView({ lat: marker.latitude, lng: marker.longitude }, 15)
      }
    }

    const clickOnMarker = (marker: IMarketWorkshopDto) => {
      zoomOnMarker(marker)
      context.emit('onSelectMarket', marker)
    }

    watchEffect(() => {
      if (!Number.isNaN(props.currentWorkshop.id)) {
        zoomOnMarker(props.currentWorkshop)
      }
    })

    return {
      attribution,
      bounds,
      clickOnMarker,
      map,
      tileLayerUrl,
      zoom
    }
  }
}

