
import { computed, onMounted, ref } from 'vue'
import { useConfigurationsStore } from '@/stores'
import { FooterLinks } from '@/containers/'

const __default__ = {
  name: 'App',
  components: {
    FooterLinks
  },
  setup () {
    const fontFamily = ref(useConfigurationsStore().brandFont ? 'Brand Font' : 'Roboto')

    const useFooter = computed(() => useConfigurationsStore().useFooter)

    const showBanner = ref(true)
    const isVisibleBanner = computed(() => useConfigurationsStore().useLocalCookieBanner)

    if (useConfigurationsStore().brandFont) {
      const brandFont = document.createElement('style')
      brandFont.appendChild(document.createTextNode(`
        @font-face {
          font-family: 'Brand Font';
          src: url(${require(`./assets/themes/fonts/${useConfigurationsStore().brandFont}`)})
        }
      `))

      document.head.appendChild(brandFont)
    }

    const useLocalCookieBanner = computed(() => useConfigurationsStore().useLocalCookieBanner)

    onMounted(() => {
      if (useLocalCookieBanner.value) {
        useConfigurationsStore().getCookiesUrl(useConfigurationsStore().hostId)
      }
    })

    const decodeCookie = (cname: string) => {
      const name = cname + '='
      const decodedCookie = decodeURIComponent(document.cookie)
      const ca = decodedCookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }

      return ''
    }

    const cookiesAccepted = computed(() => decodeCookie('cookiesAccepted') === 'true')

    const openCookiesUrl = () => {
      if (useConfigurationsStore().cookiesUrl) {
        window.open(useConfigurationsStore().cookiesUrl, '_blank')
      }
    }

    const acceptCookies = () => {
      document.cookie = 'cookiesAccepted=true'
      showBanner.value = false
    }

    return {
      acceptCookies,
      cookiesAccepted,
      fontFamily,
      isVisibleBanner,
      openCookiesUrl,
      showBanner,
      useFooter
    }
  }
}

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "5e64fd5d": (_vm.fontFamily)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__