
import { computed, Ref, ref, watch, onActivated, onMounted } from 'vue'
import SCalendar from '@/components/SCalendar.vue'
import SLoading from '@/components/SLoading.vue'

import { useConfigurationsStore, useAppointmentWizardStore } from '@/stores'
import { Breakpoints, getCurrentInstanceContext } from 'sima-suite-components'
import { getShort } from 'sima-suite-translator'
import dayjs from '@/utils/dayjs'

export default {
  name: 'AppointmentDate',
  components: {
    SCalendar,
    SLoading
  },
  setup () {
    const breakpoints = ref(Breakpoints(getCurrentInstanceContext()))

    const brandColor = ref(useConfigurationsStore().brandColor ? useConfigurationsStore().brandColor : undefined)

    const availability = computed(() => useAppointmentWizardStore().workshopAvailability)
    const allowedDates = computed(() => useAppointmentWizardStore().availableDays)
    const workshopPhone = computed(() => useAppointmentWizardStore().workshopSelected.phone)
    const availableHours: Ref<number[]> = ref([])
    const isLoading = computed(() => useAppointmentWizardStore().isLoading)
    const showCalendar = ref(true)

    const locale = () => {
      return getShort() === 'es' ? 'es' : 'en'
    }

    const rangeSelected: Ref<number|undefined> = ref()

    onMounted(() => {
      useAppointmentWizardStore().availabilityCalls = 0
      useAppointmentWizardStore().getWorkshopAvailability(useConfigurationsStore().hostId, new Date(dayjs().utc().toISOString()))
    })

    onActivated(() => {
      useAppointmentWizardStore().availabilityCalls = 0
      const date = currentDay.value.getMonth() > new Date().getMonth()
        ? new Date(dayjs(currentDay.value).startOf('month').utc(true).toISOString())
        : new Date(dayjs().utc().toISOString())

      useAppointmentWizardStore().getWorkshopAvailability(useConfigurationsStore().hostId, date)
    })

    const currentDay = computed(() => {
      if (useAppointmentWizardStore().workshopAvailabilityDate) {
        availableHours.value = useAppointmentWizardStore().availableRangeHours
        rangeSelected.value = useAppointmentWizardStore().workshopAvailabilityRange
        return new Date(useAppointmentWizardStore().workshopAvailabilityDate)
      }
      if (allowedDates.value.length > 0) {
        return new Date(allowedDates.value[0])
      }
      if (!useAppointmentWizardStore().isLoading) {
        const date = new Date()
        date.setDate(1)
        date.setMonth(date.getMonth() + 1)

        return date
      }

      return new Date()
    })

    const onSelectDate = (date: string) => {
      useAppointmentWizardStore().workshopAvailabilityDate = date
      availableHours.value = useAppointmentWizardStore().availableRangeHours
      useAppointmentWizardStore().workshopAvailabilityRange = NaN
      useAppointmentWizardStore().workshopAvailabilityAdviser = undefined
      if (availableHours.value.length > 0) {
        showCalendar.value = false
      }
    }

    watch(
      () => currentDay.value,
      (newValue) => {
        if (useAppointmentWizardStore().workshopAvailabilityDate === '' && useAppointmentWizardStore().availabilityCalls !== 0) {
          const formatDate = newValue.toISOString().slice(0, 10)
          onSelectDate(formatDate)
          showCalendar.value = true
        }
      }
    )

    const onChangeMonth = (value: string) => {
      const valueDate = resetHour(new Date(value))
      const currentDate = resetHour(currentDay.value)

      if (valueDate.getTime() !== currentDate.getTime()) {
        const valueSplit = value.split('-')
        // const firstDayOfMonth = new Date(DateTime.utc(Number(valueSplit[0]), Number(valueSplit[1]), 1).toISO())
        const firstDayOfMonth = new Date(dayjs(`${valueSplit[0]}-${valueSplit[1]}-01`).format('YYYY-MM-DD'))
        const today = new Date(dayjs().utc().toISOString())
        const updatedDate = firstDayOfMonth.getTime() < today.getTime() ? today : firstDayOfMonth
        useAppointmentWizardStore().workshopAvailabilityDate = updatedDate.toISOString().slice(0, 10)
        useAppointmentWizardStore().getWorkshopAvailability(useConfigurationsStore().hostId, updatedDate)
      }
    }

    const resetHour = (date: Date) => {
      date.setHours(0)
      date.setMinutes(0)
      date.setSeconds(0)
      date.setMilliseconds(0)

      return date
    }

    const onSelectHour = (range: number) => {
      rangeSelected.value = range
      useAppointmentWizardStore().workshopAvailabilityRange = range
      useAppointmentWizardStore().workshopAvailabilityAdviser = undefined
    }

    const goToScheduleRange = () => {
      showCalendar.value = false
    }

    const backToCalendar = () => {
      showCalendar.value = true
    }

    return {
      allowedDates,
      availability,
      availableHours,
      backToCalendar,
      brandColor,
      breakpoints,
      currentDay,
      goToScheduleRange,
      isLoading,
      onChangeMonth,
      onSelectDate,
      onSelectHour,
      rangeSelected,
      showCalendar,
      workshopPhone,
      locale
    }
  }
}
