import { AvailableHourForAdviserDto2, IWorkshopAvailabilityDto2 } from 'online-customer-services-ts'
import { setContext } from '@sentry/vue'
export const changeLocationHash = (hash:string) => {
  const currentHash = window.location.hash.split('#')
  if (currentHash.length > 2) {
    currentHash.pop()
    window.location.hash = `${currentHash.join('#')}#${hash}`
  } else {
    window.location.hash = `${window.location.hash}#${hash}`
  }
}

export const createWorkshopDate = (date: string, time:string) => {
  return new Date(`${date}T${time}Z`)
}

export const getUrlParams = () => {
  const urlHash = window.location.hash
  if (!urlHash.includes('?')) return new URLSearchParams('')
  const urlParams = urlHash.split('?')[1].split('#')[0]
  return new URLSearchParams(urlParams)
}

export const getTeamId = (availabilityList: IWorkshopAvailabilityDto2[], selectedDate: string) => {
  let adviser: AvailableHourForAdviserDto2 | undefined
  let index = -1
  for (let i = 0; i < availabilityList.length; i++) {
    adviser = availabilityList[i].advisers?.find((adviser) => {
      return adviser.adviserDate === selectedDate
    })
    if (adviser) {
      index = i
      break
    }
  }

  setContext('aditional-information', {
    availabilityItem: availabilityList[index],
    index,
    selectedDate,
    adviser
  })

  return availabilityList[index]?.teamId
}
