import { ajax } from 'rxjs/ajax'
import { Settings } from './types'

export function loadConfiguration () {
  return new Promise(resolve => {
    if (process.env.NODE_ENV !== 'production') {
      return resolve(configureDevelopmentSettings())
    } else {
      ajax.getJSON('./appSettings.json').subscribe(result => {
        return resolve(result)
      })
    }
  })
}

function configureDevelopmentSettings (settings?: Settings) {
  const env = process.env
  return {
    ...settings,
    backendUrl: env.VUE_APP_BACKEND_URL || settings?.backendUrl,
    didomiId: env.VUE_APP_DIDOMI_ID || settings?.didomiId,
    googleAnalyticsUA: env.VUE_APP_GOOGLE_ANALYTICS_UA || settings?.googleAnalyticsUA,
    host: env.VUE_APP_HOST || settings?.host,
    legalAdviserUrl: env.VUE_APP_LEGAL_ADVISER_URL || settings?.legalAdviserUrl,
    mapsKey: env.VUE_APP_MAPS_KEY || settings?.mapsKey,
    privacyPolicyUrl: env.VUE_APP_PRIVACY_POLICY_URL || settings?.privacyPolicyUrl,
    title: env.VUE_APP_TITLE || settings?.title,
    useFooter: Boolean(env.VUE_APP_USE_FOOTER) || settings?.useFooter,
    useLocalCookieBanner: Boolean(env.VUE_APP_USE_LOCAL_COOKIE_BANNER) || settings?.useLocalCookieBanner,
    useNewRGPDPolicy: Boolean(env.VUE_APP_USE_NEW_RGPD_POLICY) || settings?.useNewRGPDPolicy,
    webOwnerName: env.VUE_APP_WEB_OWNER_NAME || settings?.webOwnerName
  }
}
