
import { computed, onMounted } from 'vue'
import { useAppointmentWizardStore, useConfigurationsStore } from '@/stores'
import { WorkshopService } from '@/helpers/types'

export default {
  name: 'WorkshopServices',
  setup () {
    const MAX_REMARK_LENGTH = 70

    const services = computed<WorkshopService[]>(() => useAppointmentWizardStore().workshopServices)

    const hasServices = computed(() => services.value.length > 0)

    onMounted(() => {
      if (!hasServices.value) {
        useAppointmentWizardStore().getServices(useConfigurationsStore().hostId)
      }
    })

    const getIndexService = (id: number) => useAppointmentWizardStore().workshopServices.findIndex(workshopService => workshopService.id === id)

    const onServiceSelect = (service: WorkshopService) => {
      useAppointmentWizardStore().resetAvailability()
      const index = getIndexService(service.id)
      useAppointmentWizardStore().workshopServices[index].checked = !useAppointmentWizardStore().workshopServices[index].checked
      setTimeout(() => {
        if (useAppointmentWizardStore().workshopServices[index].hasRemarks && useAppointmentWizardStore().workshopServices[index].checked) {
          document.getElementById(service.id.toString())?.scrollIntoView(true)
        }
      }, 1)
    }

    const onRemark = (remark: string, service: WorkshopService) => {
      const index = getIndexService(service.id)
      useAppointmentWizardStore().workshopServices[index].remark = remark
    }

    return {
      MAX_REMARK_LENGTH,
      onRemark,
      onServiceSelect,
      services
    }
  }
}
