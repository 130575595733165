
import { getShort } from 'sima-suite-translator'
import { computed, Ref, ref, onMounted, watch } from 'vue'
export default {
  name: 'SCalendar',
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    locale: {
      type: String,
      default: undefined
    },
    allowedDates: {
      type: [Array as () => Date[], () => null],
      default: null
    },
    minDate: {
      type: Date,
      default: () => undefined
    },
    date: {
      type: Date,
      default: undefined
    },
    maxDate: {
      type: Date,
      default: undefined
    },
    firstDayOfWeek: {
      type: Number,
      default: 1,
      validator: (value: number) => {
        return value >= 0 && value <= 7
      }
    }
  },
  setup (props, context) {
    const calendar = ref()
    const getLocale: Ref<string> = computed(() => {
      const locale: string = props.locale || getShort()
      return locale
    })

    onMounted(() => {
      calendar.value.$el.children[1].style.width = '100%'
    })

    const transformDate = (date: Date) => {
      return date ? date.toISOString() : ''
    }

    const calendarDate = ref(transformDate(props.date).slice(0, 10))
    const pickerDate = ref(transformDate(props.date).slice(0, 10))

    const enabledDates = (day: string) => {
      if (props.allowedDates !== null) {
        const enabledDates = props.allowedDates.map((date: Date) => transformDate(date).slice(0, 10))

        return enabledDates.includes(day)
      }

      return null
    }

    const onSelectDate = () => {
      context.emit('onSelectDate', calendarDate.value)
    }

    watch(
      () => pickerDate.value,
      (newValue) => {
        if (newValue.includes('-')) {
          context.emit('onChangeMonth', newValue)
        } else {
          context.emit('onChangeYear', newValue)
        }
      }
    )

    watch(
      () => props.date,
      () => {
        pickerDate.value = transformDate(props.date).slice(0, 10)
      }
    )

    return {
      calendarDate,
      enabledDates,
      getLocale,
      transformDate,
      onSelectDate,
      calendar,
      pickerDate
    }
  }
}
