import { Contact, Vehicle } from './types'
import { IMarketWorkshopDto } from 'online-customer-services-ts'

export const createEmptyWorkshop = (): IMarketWorkshopDto => ({
  id: NaN,
  name: '',
  addressPart1: '',
  addressPart2: '',
  startingWorkHour: '',
  endingWorkHour: '',
  schedulerTimeSlot: NaN,
  brands: [],
  latitude: NaN,
  longitude: NaN,
  timeZoneId: '',
  noWorkingWeekDays: [],
  simaId: NaN,
  idInEnterprise: NaN,
  daysOfMarginToRequest: NaN,
  phone: '',
  redirectUrl: ''
})

export const createEmptyVehicle = (): Vehicle => ({
  brand: undefined,
  kilometers: undefined,
  model: '',
  plate: '',
  vin: ''
})

export const createEmptyContact = (): Contact => ({
  completeName: '',
  email: '',
  mobile: '',
  phone: ''
})
