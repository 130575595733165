
import { SHorizontalAlignment } from 'sima-suite-components'
import { computed, onMounted, reactive } from 'vue'

import { WorkshopMap } from '@/containers/'
import WorkshopsList from '@/containers/WorkshopsList/WorkshopsList.vue'

import { createEmptyWorkshop } from '@/helpers/constructors'
import { IMarketWorkshopDto } from 'online-customer-services-ts'
import { useConfigurationsStore, useAppointmentWizardStore } from '@/stores'

export default {
  name: 'Workshops',
  components: {
    WorkshopMap,
    WorkshopsList
  },
  setup () {
    const horizontalAlignment = SHorizontalAlignment.Center

    const workshopSelected = reactive(createEmptyWorkshop())
    const workshops = computed(() => useAppointmentWizardStore().workshops)

    onMounted(() => {
      useAppointmentWizardStore().getWorkshops(useConfigurationsStore().hostId)
      Object.assign(workshopSelected, useAppointmentWizardStore().workshopSelected)
    })

    const onWorkshopSelected = (workshop: IMarketWorkshopDto) => {
      useAppointmentWizardStore().resetAvailability()
      Object.assign(workshopSelected, workshop)
      useAppointmentWizardStore().workshopSelected = workshop
      useAppointmentWizardStore().vehicle.brand = undefined
    }

    return {
      horizontalAlignment,
      workshops,
      workshopSelected,
      onWorkshopSelected
    }
  }
}
